/* GLobal */

@import 'fonts.css';

/* Components */

@import 'components/footer.css';
@import 'components/header.css';
@import 'components/terminal.css';
@import 'components/page.css';
@import 'components/team.css';

#intro {
    display: none;
}
#team {
    display: none;
}
